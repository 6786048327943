import Actions from "../constants";
import { getKeycloakToken } from "lib/util";

export const fetchJumpGraphs = () => ({
  type: Actions.FETCH_JUMP_GRAPHS,
  token: getKeycloakToken(),
});

export const setJumpGraphs = (data) => ({
  type: Actions.SET_JUMP_GRAPHS,
  payload: data,
});

export const fetchGlobalGraphs = (data) => ({
  type: Actions.FETCH_GLOBAL_GRAPHS,
  token: getKeycloakToken(),
  payload: data,
});

export const setGlobalGraphs = (data) => ({
  type: Actions.SET_GLOBAL_GRAPHS,
  payload: data,
});

export const fetchJumpGraphMappings = () => ({
  type: Actions.FETCH_JUMP_GRAPH_MAPPINGS,
  token: getKeycloakToken(),
});

export const setJumpGraphMappings = (data) => ({
  type: Actions.SET_JUMP_GRAPH_MAPPINGS,
  payload: data,
});
