import Actions from "../constants";
import DialogueConstants from "../../components/constants";

export const setFacebookPages = (currentState, action) => {
  if (action.type !== Actions.SET_FACEBOOK_PAGES) {
    return currentState;
  }
  return action.payload;
};

export const setFacebookToken = (currentState, action) => {
  if (action.type !== Actions.SET_FACEBOOK_TOKEN) {
    return currentState;
  }
  return action.payload;
};

export const setUsedFacebookPage = (currentState, action) => {
  if (action.type !== Actions.SET_USED_FACEBOOK_PAGE) {
    return currentState;
  }
  return action.payload;
};

export const setUsedWebChannelConfig = (currentState, action) => {
  if (action.type !== Actions.SET_USED_WEB_CHANNEL_CONFIG) {
    return currentState;
  }
  return action.payload;
};

export const setUsedVoiceChannelConfig = (currentState, action) => {
  if (action.type !== Actions.SET_USED_VOICE_CHANNEL_CONFIG) {
    return currentState;
  }
  return action.payload;
};

export const setDialogueGraphChannel = (currentState, action) => {
  if (action.type !== Actions.SET_DIALOGUE_GRAPH_CHANNEL) {
    return currentState;
  }

  return action.payload;
};

export const setChannelLimitations = (currentState, action) => {
  if (action.type !== Actions.SET_CHANNEL_LIMITATIONS) {
    return currentState;
  }

  return action.payload;
};

export const setChannelShareLinks = (currentState, action) => {
  if (action.type !== Actions.SET_CHANNEL_SHARE_LINKS) {
    return currentState;
  }

  return action.payload;
};
