import { of } from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime,
  map,
  switchMap,
} from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import { getAgentUrl } from "../../lib/util";
import {
  addEvent,
  addEventFulfilled,
  createNoteFulfilled,
  setAgentAssistResponse,
  setAttachmentFile,
  setConversationAttachments,
  setConversationIdentity,
  setConversations,
  setConversationsCount,
  setConversationTracker,
  setTenantIdentity,
  setTicketAnswer,
  setTranslatedConversation,
  updateConversationFulfilled,
  updateConversationReadFulfilled,
  updatePostFulfilled,
  setConversationEvents,
  setConversationEventsFulfilled,
} from "../actions/conversations";
import dayjs from "dayjs";
import getConfig from "../../lib/config";
import {
  setConversation,
  setConversationsCountAssigned,
  setConversationsCountUnassigned,
} from "../actions/conversations";
import { setContact } from "../actions/contacts";
import { TENANT_SEPARATOR_SYMBOL } from "./constants";
import { showErrorSnackbar, showSuccessSnackbar } from "../actions/snackbar";
import {
  fetchUserConversations,
  setUserConversations,
} from "../actions/smartDoc/documents";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST, REACT_APP_AGENT_HOST } = publicRuntimeConfig;

export const fetchConversationsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CONVERSATIONS),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations?language=${
          action.payload.language || state$.value.language
        }&offset=${action.payload.pageOffset}&start=${
          action.payload.startDate
        }&until=${action.payload.endDate}&order=${action.payload.order}&type=${
          action.payload.sortType
        }&topic=${action.payload.selectedTopic}&limit=50&is_flagged=${
          action.payload.isFlagged
        }&user_gave_feedback=${
          action.payload.userGaveFeedback
        }&filter_empty_messages=${action.payload.emptyMessageFilter}&channel=${
          action.payload.channel
        }&text_query=${action.payload.text}&bot_id=${
          action.payload.inboxId
        }&label_ids=${action.payload.labelId}&case_status=${
          action.payload.caseStatus
        }&assigned_to=${action.payload.assignedTo}&has_used_freetext=${
          action.payload.hasUsedFreetext
        }&has_created_ticket=${
          action.payload.hasCreatedTicket
        }&has_denied_ticket=${
          action.payload.hasDeniedTicket
        }&has_used_suggestion=${
          action.payload.hasUsedSuggestion
        }&has_triggered_fallback=${
          action.payload.hasUsedFallback
        }&review_state=${action.payload.reviewState}&include_archived=${
          action.payload.includeArchived
        }`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          if (action.payload.pageOffset === 0) {
            return of(
              setConversations(
                response.response.result.filter(
                  (e) => !e?.sender_id.includes("agentassist")
                )
              ),
              setConversationsCount(response.response.count),
              setConversationsCountUnassigned(
                response.response.count_unassigned
              ),
              setConversationsCountAssigned(response.response.count_assigned)
            );
          } else {
            return of(
              setConversations(
                state$.value.conversations.concat(response.response.result)
              )
            );
          }
        }),
        catchError((error) => {
          return of(setConversations(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchConversationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CONVERSATION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.payload}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            setConversation(response.response.result),
            setContact(response.response.result?.contact)
          );
        }),
        catchError((error) => {
          return of(
            setConversation({}),
            setContact({}),
            errorMessage(error.message)
          );
        })
      );
    })
  );
};

export const fetchConversationTracker = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CONVERSATION_TRACKER),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${getAgentUrl(state$.value.language)}/conversations/${
          action.conversationId
        }${TENANT_SEPARATOR_SYMBOL}${state$.value.tenant.toLowerCase()}/tracker?include_events=all`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setConversationTracker(response.response);
        }),
        catchError((error) => {
          return of(setConversationTracker({}), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchConversationIdentity = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CONVERSATION_IDENTITY),
    debounceTime(100),
    switchMap((action) => {
      return ajax({
        url: `${getAgentUrl(state$.value.language)}/conversations/${
          action.conversationId
        }${TENANT_SEPARATOR_SYMBOL}${state$.value.tenant.toLowerCase()}/identity`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        map((response) => {
          return setConversationIdentity(
            action.conversationId,
            response?.response
          );
        }),
        catchError((error) => {
          return of(
            setConversationIdentity(action.conversationId, null),
            errorMessage(error.message)
          );
        })
      );
    })
  );
};

export const saveTenantIdentityEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.SAVE_TENANT_IDENTITY),
    debounceTime(100),
    switchMap((action) => {
      return ajax
        .post(
          `${getAgentUrl(state$.value.language)}/conversations/${
            action.conversationId
          }${TENANT_SEPARATOR_SYMBOL}${state$.value.tenant.toLowerCase()}/tracker/events`,
          JSON.stringify([
            { event: "slot", name: "user_is_authenticated", value: true },
            { event: "slot", name: "aareon_tenant_id", value: action.tenantId },
          ]),
          {
            "Content-Type": "application/json",
            Authorization: action.token,
            Tenant_Realm: state$.value.tenant,
            Type_Origin: "dashboard",
          }
        )
        .pipe(
          map((response) => {
            return setTenantIdentity(action.tenantId, response?.response);
          }),
          catchError((error) => {
            return of(
              setTenantIdentity(action.tenantId, null),
              errorMessage(error.message)
            );
          })
        );
    })
  );
};

export const createNoteEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.CREATE_NOTE),
    debounceTime(100),
    switchMap((action) => {
      return ajax
        .post(
          `${getAgentUrl(state$.value.language)}/conversations/${
            action.conversationId
          }${TENANT_SEPARATOR_SYMBOL}${state$.value.tenant.toLowerCase()}/tracker/events`,
          JSON.stringify([{ event: "note_created", ...action.payload }]),
          {
            "Content-Type": "application/json",
            Authorization: action.token,
            Tenant_Realm: state$.value.tenant,
            Type_Origin: "dashboard",
          }
        )
        .pipe(
          concatMap((response) => {
            return of(
              createNoteFulfilled(),
              setConversation({
                ...state$.value.conversation,
                events: [
                  ...state$.value.conversation.events,
                  {
                    ...action.payload,
                    event: "note_created",
                    timestamp: dayjs().unix(),
                  },
                ],
              })
            );
          }),
          catchError((error) => {
            return of(errorMessage(error.message));
          })
        );
    })
  );
};

export const createEventEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.ADD_EVENT),
    debounceTime(100),
    switchMap((action) => {
      return ajax
        .post(
          `${getAgentUrl(state$.value.language)}/conversations/${
            action.conversationId
          }${TENANT_SEPARATOR_SYMBOL}${state$.value.tenant.toLowerCase()}/tracker/events`,
          JSON.stringify([{ event: action.event, ...action.payload }]),
          {
            "Content-Type": "application/json",
            Authorization: action.token,
            Tenant_Realm: state$.value.tenant,
            Type_Origin: "dashboard",
          }
        )
        .pipe(
          concatMap((response) => {
            return of(
              addEventFulfilled(),
              setConversation({
                ...state$.value.conversation,
                events: [
                  ...state$.value.conversation.events,
                  {
                    ...action.payload,
                    event: action.event,
                    timestamp: dayjs().unix(),
                  },
                ],
              })
            );
          }),
          catchError((error) => {
            return of(errorMessage(error.message));
          })
        );
    })
  );
};

export const updateConversationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.UPDATE_CONVERSATION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.conversationId}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { ...action.payload.request },
      }).pipe(
        concatMap((response) => {
          return of(
            updateConversationFulfilled(),
            addEvent(action.conversationId, action.payload.event.name, {
              data: action.payload.event.data,
            }),
            setConversations(
              state$.value.conversations.map((e) => {
                if (e.sender_id === action.conversationId)
                  return { ...e, ...action.payload.request };
                return e;
              })
            )
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const updateConversationReadEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.UPDATE_CONVERSATION_READ),
    debounceTime(250),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.conversationId}/read`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            updateConversationReadFulfilled(),
            setConversations(
              state$.value.conversations.map((e) => {
                if (e.sender_id === action.conversationId)
                  return { ...e, read_messages: e.number_user_messages };
                return e;
              })
            )
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const updatePostEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.UPDATE_POST),
    debounceTime(250),
    switchMap((action) => {
      const requestBody = {
        message: action.payload,
        sender: `${action.conversationId}`,
        language: state$.value.language,
      };

      if (action.dialogueGraphId) {
        requestBody["dialogue_graph_id"] = action.dialogueGraphId;
      }

      return ajax({
        url: `${getAgentUrl(state$.value.language)}/webhooks/rest/webhook`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: requestBody,
      }).pipe(
        concatMap((response) => {
          return of(
            updatePostFulfilled(),
            setAgentAssistResponse(action.conversationId, response.response)
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchTranslatedConversationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TRANSLATED_CONVERSATION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.payload}/translate?target_language=${action.language}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setTranslatedConversation(response.response.result));
        }),
        catchError((error) => {
          return of(setTranslatedConversation(null));
        })
      );
    })
  );
};

export const fetchConversationAttachmentsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CONVERSATION_ATTACHMENTS),
    debounceTime(250),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.conversationId}/files`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            setConversationAttachments(response.response.result),
            setAttachmentFile(null)
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchAttachmentEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_ATTACHMENT),
    debounceTime(250),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.conversationId}/files/${action.fileId}`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        responseType: "blob",
      }).pipe(
        concatMap((response) => {
          return of(setAttachmentFile(response.response));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchTicketAnswerEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_TICKET_ANSWER),
    debounceTime(250),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.conversationId}/ticket-status`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setTicketAnswer(response.response));
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const archiveConversationEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.ARCHIVE_CONVERSATION),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.senderId}/archive`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          // Get current conversations from state
          const currentConversations = state$.value.userConversations || [];

          // Filter out the archived conversation
          const updatedConversations = currentConversations.filter(
            (conv) => conv.sender_id !== action.senderId
          );

          return of(
            setUserConversations(updatedConversations),
            showSuccessSnackbar(
              "Snackbars.documentConversationsArchivedSuccess"
            )
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.documentConversationsArchivedError")
          );
        })
      );
    })
  );
};

export const setConversationEventsEpic = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.SET_CONVERSATION_EVENTS),
    debounceTime(0),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/conversations/${action.payload.sender_id}/copy`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { events: action.payload.events, meta: action.payload.meta },
      }).pipe(
        map((response) => {
          console.log("Response", response);
          console.log("Response", response.response.result.sender_id);
          return setConversationEventsFulfilled(
            response.response.result.sender_id
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};
