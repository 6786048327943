import Actions from "../constants";
import { getKeycloakToken } from "../../lib/util";

export const fetchDialogueStatistics = (
  startDate,
  endDate,
  dialogueGraphId
) => ({
  type: Actions.FETCH_DIALOGUE_STATISTICS,
  payload: {
    startDate,
    endDate,
    dialogueGraphId,
  },
  token: getKeycloakToken(),
});

export const fetchDialogueStatisticsFulfilled = (data) => ({
  type: Actions.FETCH_DIALOGUE_STATISTICS_FULFILLED,
  payload: data,
});
