import { of } from "rxjs";
import { mergeMap, map, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";

import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import getConfig from "lib/config";
import { fetchDialogueStatisticsFulfilled } from "../actions/dialogueStatistics";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchDialogueStatistics = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_DIALOGUE_STATISTICS),
    mergeMap((action) => {
      return ajax
        .get(
          `${REACT_APP_ACTION_HOST}/dialogue_statistics/diagram_data?start=${action.payload.startDate}&until=${action.payload.endDate}&bot_id=${action.payload.dialogueGraphId}`,
          {
            "Content-Type": "application/json",
            Authorization: action.token,
            Tenant_Realm: state$.value.tenant,
            Type_Origin: "dashboard",
          }
        )
        .pipe(
          map((response) => {
            let statistics = response?.response;
            return fetchDialogueStatisticsFulfilled(statistics);
          }),
          catchError((error) => {
            if (error.status === 404) {
              return of(fetchDialogueStatisticsFulfilled([]));
            }
            return of(errorMessage(error.message));
          })
        );
    })
  );
