import {
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  merge,
} from "rxjs";
import { switchMap, catchError, debounceTime, concatMap, mergeMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../../constants";
import { errorMessage } from "../../actions/error";
import { ajax } from "rxjs/ajax";
import getConfig from "lib/config";
import { Action } from "redux";
import { showErrorSnackbar, showSuccessSnackbar } from "store/actions/snackbar";

import {
  deleteCollectionFulfilled,
  fetchCollections,
  setCollection,
  setCollections,
  upsertCollectionFailed,
  upsertCollectionFulfilled,
  addDocumentsToCollection,
  removeDocumentsFromCollection,
  addDocumentsToCollectionFulfilled,
  removeDocumentsFromCollectionFulfilled,
} from "store/actions/smartDoc/collections";
import { setDocumentsInfo } from "store/actions/smartDoc/documents";

const { publicRuntimeConfig } = getConfig();
const { REACT_APP_AGENT_HOST, REACT_APP_ACTION_HOST } = publicRuntimeConfig;

export const fetchCollectionsEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_COLLECTIONS),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections?only_root_level=true`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setCollections(response.response.result));
        }),
        catchError((error) => {
          return of(setCollections(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const fetchCollectionEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_COLLECTION),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections/${action.collection_id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setCollection(response.response.result));
        }),
        catchError((error) => {
          return of(setCollection(null), errorMessage(error.message));
        })
      );
    })
  );
};

export const expandCollectionEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
      collections: any;
      documentsInfo: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.EXPAND_COLLECTION),
    debounceTime(0),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections/items?parent_id=${action.collection_id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            setCollections([
              ...state$.value?.collections,
              ...response.response.result.collections.items,
            ]),
            setDocumentsInfo([
              ...state$.value?.documentsInfo,
              ...response.response.result.documents.items,
            ])
          );
        }),
        catchError((error) => {
          return of(errorMessage(error.message));
        })
      );
    })
  );
};

export const upsertCollectionEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(ActionConstants.UPSERT_COLLECTION),
    switchMap((action: any) => {
      // Parse payload if it's a string
      let parsedPayload;
      try {
        parsedPayload =
          typeof action.payload === "string"
            ? JSON.parse(action.payload)
            : action.payload;
      } catch (error) {
        console.error("[upsertCollectionEpic] Failed to parse payload:", error);
        return of(
          upsertCollectionFailed(action.option, action.editCollectionId, error)
        );
      }

      if (!Array.isArray(parsedPayload)) {
        parsedPayload = [parsedPayload];
      }

      // Process each collection in payload
      const collectionsToUpdate = parsedPayload.map((collection: any) => {
        const collectionId = collection.id;
        const existingCollection = state$.value.collections.find(
          (c: any) => c.id === collectionId
        );

        // Convert existing and new documents to arrays of document IDs
        const existingDocuments = (existingCollection?.documents || []).map(
          (doc: any) => (typeof doc === "string" ? doc : doc.document_id)
        );
        const newDocuments = (collection.documents || []).map((doc: any) =>
          typeof doc === "string" ? doc : doc.document_id
        );

        // Compute documents to add and remove
        const documentsToAdd = newDocuments.filter(
          (docId: string) => !existingDocuments.includes(docId)
        );
        const documentsToRemove = existingDocuments.filter(
          (docId: string) => !newDocuments.includes(docId)
        );

        // Create collection data without documents attribute
        const { documents, ...collectionDataWithoutDocs } = collection;

        return {
          collectionId,
          documentsToAdd,
          documentsToRemove,
          collectionData: collectionDataWithoutDocs,
        };
      });

      console.log("[Collections to Update]", collectionsToUpdate);

      // First update all collections
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: JSON.stringify(collectionsToUpdate.map((c) => c.collectionData)),
      }).pipe(
        concatMap(() => {
          const actions = [
            upsertCollectionFulfilled(action.option, action.editCollectionId),
            fetchCollections(),
          ];

          // Add actions for document updates
          collectionsToUpdate.forEach((collection) => {
            if (collection.documentsToAdd.length > 0) {
              console.log("[upsertCollectionEpic] Adding documents:", {
                collectionId: collection.collectionId,
                documents: collection.documentsToAdd,
              });
              actions.push(
                addDocumentsToCollection(
                  collection.collectionId,
                  collection.documentsToAdd
                )
              );
            }
            if (collection.documentsToRemove.length > 0) {
              actions.push(
                removeDocumentsFromCollection(
                  collection.collectionId,
                  collection.documentsToRemove
                )
              );
            }
          });

          return of(...actions);
        }),
        catchError((error) => {
          console.error("[upsertCollectionEpic] Failed:", error);
          return of(
            upsertCollectionFailed(
              action.option,
              action.editCollectionId,
              error
            ),
            showErrorSnackbar("Snackbars.collectionUpdateError")
          );
        })
      );
    })
  );
};

export const deleteCollectionEpic = (
  action$: {
    pipe: (
      arg0: (source: Observable<Action<any>>) => Observable<Action<any>>,
      arg1: MonoTypeOperatorFunction<unknown>,
      arg2: OperatorFunction<unknown, any>
    ) => any;
  },
  state$: {
    value: {
      tenant: any;
    };
  }
) => {
  return action$.pipe(
    ofType(ActionConstants.DELETE_COLLECTION),
    debounceTime(100),
    switchMap((action: any) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections/${action.collection_id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            deleteCollectionFulfilled(),
            showSuccessSnackbar("Snackbars.collectionDeletionSuccess", {
              vertical: "top",
              horizontal: "right",
            }),
            fetchCollections()
          );
        }),
        catchError((error) => {
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.collectionDeletionError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    })
  );
};

export const addDocumentsToCollectionEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(ActionConstants.ADD_DOCUMENTS_TO_COLLECTION),
    mergeMap((action: any) => {
      console.log("[addDocumentsToCollectionEpic] Adding documents:", {
        collectionId: action.collection_id,
        documents: action.document_ids,
      });

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections/${action.collection_id}/documents`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { document_ids: action.document_ids },
      }).pipe(
        concatMap(() => {
          console.log(
            "[addDocumentsToCollectionEpic] Documents added successfully"
          );
          return of(addDocumentsToCollectionFulfilled(), fetchCollections());
        }),
        catchError((error) => {
          console.error(
            "[addDocumentsToCollectionEpic] Failed to add documents:",
            error
          );
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.documentAddError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    }, 5)
  );
};

export const removeDocumentsFromCollectionEpic = (
  action$: any,
  state$: any
) => {
  return action$.pipe(
    ofType(ActionConstants.REMOVE_DOCUMENTS_FROM_COLLECTION),
    mergeMap((action: any) => {
      console.log("[removeDocumentsFromCollectionEpic] Removing documents:", {
        collectionId: action.collection_id,
        documents: action.document_ids,
      });

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/collections/${action.collection_id}/documents`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: { document_ids: action.document_ids },
      }).pipe(
        concatMap(() => {
          console.log(
            "[removeDocumentsFromCollectionEpic] Documents removed successfully"
          );
          return of(
            removeDocumentsFromCollectionFulfilled(),
            fetchCollections()
          );
        }),
        catchError((error) => {
          console.error(
            "[removeDocumentsFromCollectionEpic] Failed to remove documents:",
            error
          );
          return of(
            errorMessage(error.message),
            showErrorSnackbar("Snackbars.documentRemoveError", {
              vertical: "top",
              horizontal: "right",
            })
          );
        })
      );
    }, 5) // Concurrency limit of 5 parallel requests
  );
};
