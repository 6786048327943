import { of } from "rxjs";
import { switchMap, catchError, debounceTime, concatMap } from "rxjs/operators";
import { ofType } from "redux-observable";
import ActionConstants from "../constants";
import { errorMessage } from "../actions/error";
import { ajax } from "rxjs/ajax";
import getConfig from "lib/config";
const { publicRuntimeConfig } = getConfig();
const { REACT_APP_ACTION_HOST } = publicRuntimeConfig;
import {
  createFacebookPageFulfilled,
  createVoiceChannelConfigFulfilled,
  createWebChannelConfigFulfilled,
  setFacebookPages,
  setUsedFacebookPage,
  setUsedVoiceChannelConfig,
  setUsedWebChannelConfig,
} from "../actions/channels";
import {
  fetchChannelShareLinks,
  setChannelLimitations,
  setChannelShareLinks,
} from "../actions/dialogues";

export const fetchDialogueChannelLimitations = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CHANNEL_LIMITATIONS),
    debounceTime(200),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/${action.channel}/limitations`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      }).pipe(
        concatMap((response) => {
          const result = response.response;
          let status = response.status === 200 ? "success" : "error";

          return of(setChannelLimitations(result));
        }),
        catchError((error) =>
          of(
            setChannelLimitations({
              buttons: { enabled: true },
              dashboard: {
                design: { enabled: true },
                demo: { enabled: true },
                share: { enabled: true },
              },
            })
          )
        )
      );
    })
  );
};

export const fetchDialogueChannelShareLinks = (action$, state$) => {
  return action$.pipe(
    ofType(ActionConstants.FETCH_CHANNEL_SHARE_LINKS),
    debounceTime(200),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/${action.channel}/test_access?dialogue_graph_id=${state$.value.dialogueGraphId}`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
        },
      }).pipe(
        concatMap((response) => {
          const result = response.response;
          let status = response.status === 200 ? "success" : "error";

          return of(setChannelShareLinks(result));
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );
};

export const fetchFacebookPagesEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_FACEBOOK_PAGES),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/facebook/pages?token=${state$.value.channelFacebookToken}&dialogue_graph_id=${state$.value.dialogueGraphId}`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          let data = response?.response?.data;
          return of(setFacebookPages(data));
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const fetchUsedFacebookPageEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_USED_FACEBOOK_PAGE),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/facebook/page?dialogue_graph_id=${state$.value.dialogueGraphId}`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          let data = response?.response;
          return of(setFacebookPages([data]), setUsedFacebookPage(data));
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const createFacebookPageEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.CREATE_FACEBOOK_PAGE),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/facebook/page?token=${state$.value.channelFacebookToken}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: {
          dialogue_graph_id: state$.value.dialogueGraphId,
          page_id: action.pageId,
        },
      }).pipe(
        concatMap((response) => {
          const pageName = state$.value.channelFacebookPages.find(
            (e) => e.id === action.pageId
          )["name"];
          return of(
            createFacebookPageFulfilled(),
            setUsedFacebookPage({ id: action.pageId, name: pageName }),
            fetchChannelShareLinks("facebook")
          );
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const deleteFacebookPageEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.DELETE_LINKED_FACEBOOK_PAGE),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/facebook/page?dialogue_graph_id=${state$.value.dialogueGraphId}`,
        method: "DELETE",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(setUsedFacebookPage({ id: 0, name: "" }));
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const fetchUsedWebChannelConfigEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_USED_WEB_CHANNEL_CONFIG),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/web/configs?dialogue_graph_id=${state$.value.dialogueGraphId}`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          let data = response?.response?.result;
          return of(setUsedWebChannelConfig(data));
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const createWebChannelConfigEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.CREATE_WEB_CHANNEL_CONFIG),
    switchMap((action) => {
      let payload = {
        dialogue_graph_id: state$.value.dialogueGraphId,
      };
      if (action.domain) {
        payload["entries"] = action.domain.map((domain) => ({
          dialogue_graph_id: state$.value.dialogueGraphId,
          ...domain,
        }));
      }

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/web/configs`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: payload,
      }).pipe(
        concatMap((response) => {
          return of(
            createWebChannelConfigFulfilled(),
            setUsedWebChannelConfig(response.response?.result || []),
            fetchChannelShareLinks("web")
          );
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const deleteWebChannelConfigEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.DELETE_LINKED_WEB_CHANNEL_CONFIG),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/web/configs/${action.payload}`,
        method: "DELETE",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            setUsedWebChannelConfig(
              state$.value.channelUsedWebConfig.filter(
                (e) => !action.payload.includes(e.id)
              )
            )
          );
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const fetchUsedVoiceChannelConfigEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.FETCH_USED_VOICE_CHANNEL_CONFIG),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/voice/configs?dialogue_graph_id=${state$.value.dialogueGraphId}`,
        method: "GET",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          let data = response?.response?.result;
          return of(setUsedVoiceChannelConfig(data));
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const createVoiceChannelConfigEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.CREATE_VOICE_CHANNEL_CONFIG),
    switchMap((action) => {
      let payload = {
        dialogue_graph_id: state$.value.dialogueGraphId,
      };
      if (action.phone_number) {
        payload["entries"] = action.phone_number.map((phone_number) => ({
          dialogue_graph_id: state$.value.dialogueGraphId,
          ...phone_number,
        }));
      }

      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/voice/configs`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
        body: payload,
      }).pipe(
        concatMap((response) => {
          return of(
            createVoiceChannelConfigFulfilled(),
            setUsedVoiceChannelConfig(response.response?.result || []),
            fetchChannelShareLinks("voice")
          );
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );

export const deleteVoiceChannelConfigEpic = (action$, state$) =>
  action$.pipe(
    ofType(ActionConstants.DELETE_LINKED_VOICE_CHANNEL_CONFIG),
    switchMap((action) => {
      return ajax({
        url: `${REACT_APP_ACTION_HOST}/channels/voice/configs/${action.payload}`,
        method: "DELETE",
        headers: {
          Authorization: action.token,
          Tenant_Realm: state$.value.tenant,
          Type_Origin: "dashboard",
        },
      }).pipe(
        concatMap((response) => {
          return of(
            setUsedVoiceChannelConfig(
              state$.value.channelUsedVoiceConfig.filter(
                (e) => !action.payload.includes(e.id)
              )
            )
          );
        }),
        catchError((error) => of(errorMessage(error.message)))
      );
    })
  );
